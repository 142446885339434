import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import axios from 'axios';
import CloseSidebar from "../../Main/CloseSidebar";
import Balance from "../../Main/Components/Balance";

function Info(props) {
    CloseSidebar();

    const API_SCRIPT = process.env.REACT_APP_API_SCRIPT;
    const [isLoaded, setIsLoaded] = useState(false);
    const [isPayInfo, setPayInfo] = useState([]);

    const config = {
        headers : {
            'Content-Type' : 'application/x-www-form-urlencoded'
        }
    };

    useEffect(() => {
        document.title = 'Платежи | Права Мобил';

        setIsLoaded(true);

        const data = {
            path: 'client/pay/info',
            school_id: props.auth.school_id,
            client_id: props.auth.client_id
        };

        axios.post(API_SCRIPT, data, config).then(response => {
            response = response.data;

            if (response.status === 'done') {
                setPayInfo(response);

                setIsLoaded(false)
            }
        })
    }, [])

    const moneyFormat = amount => {
        let format = parseInt(amount).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, "$1 ");
        format = format.split('.');
        return format.shift()
    }

    return (
         isLoaded === false && isPayInfo ? (
            <div className={'pay-page' + (isPayInfo.expired ? ' is-debt' : '')}>
                { isPayInfo.pay < isPayInfo.amount ? (
                    <div className="pay-page-info-header text-center p-4">
                        <div className="pay-page-info-header-date-text">{ isPayInfo.expired ? 'Задолженность за' : 'Ближайший платеж до' } { isPayInfo.nextDate }</div>
                        <div className="pay-page-info-header-amount">{ moneyFormat(isPayInfo.nextAmount) } руб.</div>
                    </div>
                ) : (
                    <div className="pay-page-info-header text-center p-4">
                        <div className="pay-page-info-header-date-text">Договор оплачен</div>
                    </div>
                ) }
                <Balance client={props.client} school={props.school} auth={props.auth} />
                <div className="pay-page-content">
                    <div className="pay-page-content-item">
                        <div className="pay-page-content-item-title">Стоимость договора</div>
                        <div className="pay-page-content-item-amount">{ moneyFormat(isPayInfo.amount) } руб.</div>
                    </div>
                    <div className="pay-page-content-item">
                        <div className="pay-page-content-item-title">Оплачено</div>
                        <div className="pay-page-content-item-amount">{ moneyFormat(isPayInfo.contractPay) } руб.</div>
                        <div className="pay-page-content-item-text">Общий остаток { moneyFormat(isPayInfo.debt) } руб.</div>
                    </div>
                    <div className="pay-page-content-item">
                        <div className="pay-page-content-item-title">Дополнительные услуги</div>
                        <div className="pay-page-content-item-amount">{ moneyFormat(isPayInfo.sPay) } руб.</div>
                    </div>
                    <div className="pay-page-buttons text-center">
                        { (isPayInfo.debt > 0 && isPayInfo.balance.money >= isPayInfo.debt) ? (<NavLink to="/pay/next">{ isPayInfo.expired ? 'Оплата задолженности' : 'Ближайшая оплата' }</NavLink>) : ('') }
                        { (isPayInfo.debt > isPayInfo.nextAmount && isPayInfo.balance.money >= isPayInfo.debt) ? (<NavLink to="/pay/early">Досрочная оплата</NavLink>) : ('') }
                    </div>
                </div>
            </div>
        ) : (
            <div className="content">
                <div className="loader">
                    <div className="circle"></div>
                    <div className="circle"></div>
                    <div className="circle"></div>
                    <div className="circle"></div>
                    <div className="circle"></div>
                </div>
            </div>
        )
    )
}

export default Info;
