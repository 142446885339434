import React, {useEffect, useState} from "react";
import toast, {Toaster} from 'react-hot-toast';
import axios from "axios";
import Plyr from "plyr";
import CloseSidebar from "../../Main/CloseSidebar";

import 'plyr-react/plyr.css';
import {NavLink} from "react-router-dom";
//import InternalTimer from "../Examens/InternalTimer";
//import Countdown from "react-countdown";

const TheoryVideos = (props) => {
    CloseSidebar();

    const API_SCRIPT = process.env.REACT_APP_API_SCRIPT;

    const [isLoaded, setIsLoaded] = useState(false);
    const [themes, setThemes] = useState([]);
    const [videosCount, setVideosCount] = useState(0);
    const [videosFullViewsCount, setVideosFullViewsCount] = useState(0);
    const [isPayModal, setPayModal] = useState(false);

    const [isVideoModal, setVideoModal] = useState(false);
    const [isVideoModalContent, setVideoModalContent] = useState([]);

    const [activate, setActivate] = useState(false);
    const [price, setPrice] = useState(false);

    const [isLoader, setLoader] = useState(false);
    const [isTestModal, setTestModal] = useState(false);
    const [isTestModalContent, setTestModalContent] = useState([]);
    const [isTestModalMode, setTestModalMode] = useState(false);
    //const [isDuration, setDuration] = useState(0);
    const [isTimeout, setTimeout] = useState(false);
    const [isQuestionId, setQuestionId] = useState(0);
    const [isQuestion, setQuestion] = useState([]);
    const [isSelect, setSelect] = useState(false);
    const [isSelectValue, setSelectValue] = useState(false);
    const [answers, setAnswers] = useState([]);
    const [isQuestions, setQuestions] = useState([]);
    let questions = JSON.parse(localStorage.getItem('test-questions'));

    const [isResult, setResult] = useState(false);
    const [isResultErrors, setResultErrors] = useState(0);

    const redirectURI = window.location.href;
    let showTimer,
        dateVideo = false;

    let body = document.getElementById('body');

    const config = {
        headers : {
            'Content-Type' : 'application/x-www-form-urlencoded'
        }
    };

    useEffect(() => {
        document.title = 'Теория онлайн | Права Мобил';

        const data = {
            path: 'client/theory/pdd/videos',
            client_id: props.auth.client_id
        };

        setIsLoaded(true);

        axios.post(API_SCRIPT, data, config).then(response => {
            response = response.data;

            if (response.status === 'done') {
                if (response.pay) {
                    if (response.pay === 'pay-error' || response.pay === 'payid-error') {
                        toast.error('Произошла ошибка: ' + response.pay + '! Обратитесь к Администрации ПраваМобил.', {
                            duration: 5000,
                            position: 'bottom-center'
                        });
                    }

                    if (response.pay === 'pay-success') {
                        toast.success('Видео уроки успешно оплачены', {
                            duration: 5000,
                            position: 'bottom-center'
                        });
                    }
                }

                setThemes(response.themes);
                setPrice(response.price);
                setActivate(response.activate);
                setVideosFullViewsCount(response.fullviews);
                setVideosCount(response.videocount);
                setIsLoaded(false);
            }
        });
    }, []);

    const videoOpen = (video) => {
        setVideoModalContent(video);
        setVideoModal(true);
        body.classList.add('overflow-hidden');
    }

    const videoClose = () => {
        setVideoModalContent([]);
        setVideoModal(false);
        clearInterval(showTimer);
        body.classList.remove('overflow-hidden');
    }

    const payOpen = () => {
        setPayModal(true);
    }

    const payClose = () => {
        setPayModal(false);
        setIsLoaded(false);
    }

    const testStart = theme => {
        localStorage.setItem('test-questions', JSON.stringify(theme.test.questions));
        setQuestion(theme.test.questions[0])
        //setDuration(Date.now() + (theme.test.questions.length * 60 * 1000));
        setTestModalContent(theme);
        setTestModalMode('test-start');
        setTestModal(true);
    }

    /*const timer = isDuration => {
        let dur = InternalTimer(isDuration);

        setDuration(dur);

        const toTime = seconds => {
            var date = new Date(null);
            date.setSeconds(seconds);
            return date.toISOString().substr(14, 5);
        }

        if (isDuration > 0) {
            return <div className="text-red text-bold">{ toTime(isDuration) }</div>
        } else {
            setTimeout(true);
            answersSend();
            return <div className="text-red text-bold">Время вышло</div>
        }
    }*/

    /*const TimerRenderer = ({ hours, minutes, seconds, completed }) => {
        if (completed) {
            setTimeout(true);
            answersSend();
            return <div className="text-red text-bold">Время вышло</div>
        } else {
            hours = hours < 10 ? '0' + hours : hours;
            minutes = minutes < 10 ? '0' + minutes : minutes;
            seconds = seconds < 10 ? '0' + seconds : seconds;

            return <div className="text-red text-bold">{ minutes + ':' + seconds }</div>
        }
    }*/

    const answerSelect = (question, answer) => {
        setSelectValue(answer)

        let index = false,
            answersData = answers;

        answersData.map((element, key) => {
            if (element.question === question) index = key;
        });

        if (index) {
            answersData[index]['answer'] = answer;
        } else {
            answersData = answers.concat({
                question: question,
                answer: answer
            });
        }

        setAnswers(answersData);
        setSelect(true);
    }

    const nextQuestion = () => {
        setSelect(false);
        setSelectValue(false);

        localStorage.setItem('test-answers', JSON.stringify(answers));

        if ((isQuestionId + 1) < questions.length) {
            setQuestion(questions[isQuestionId + 1]);
            setQuestionId(isQuestionId + 1);
        } else {
            setLoader(true);
            answersSend();
        }
    }

    const resetTest = () => {
        //setDuration(0);
        setQuestionId(0)
        setAnswers([]);
        localStorage.removeItem('test-answers');
        testStart(isTestModalContent)
    }

    const QuestionList = () => {
        setLoader(true);

        const data = {
            path: 'client/theory/pdd/videos/test/answers',
            answers: answers
        }

        axios.post(API_SCRIPT, data, config).then(response => {
            response = response.data;

            if (response.status === 'done') {
                setQuestions(response.questions);
                setQuestion(response.questions[0]);
                setQuestionId(0);
                localStorage.removeItem('test-answers');
                setTestModalMode('test-answers');
                setLoader(false);
            }
        });
    }

    const QuestionListAnswerClick = key => {
        setLoader(true);
        setQuestion(isQuestions[key]);
        setQuestionId(key);
        setLoader(false);
    }

    const NextTestVideoOpen = video => {
        TestModalClose();

        let thisId = false;

        themes.map((theme, index) => {
            if (theme.id === isTestModalContent.id && !thisId) thisId = index;
        });

        videoOpen(themes[(thisId+1)].videos[0]);
    }

    const ThisTestVideoOpen = video => {
        TestModalClose();
        videoOpen(isTestModalContent.videos[0]);
    }

    const TestModalClose = () => {
        setTestModal(false);
        localStorage.removeItem('test-questions');
        localStorage.removeItem('test-answers');
    }

    const answersSend = () => {
        const data = {
            path: 'client/theory/pdd/videos/test/result',
            client_id: props.auth.client_id,
            answers: answers,
            questions: questions,
            theme: isTestModalContent.id
        }

        axios.post(API_SCRIPT, data, config).then(response => {
            response = response.data;

            if (response.status === 'done') {
                setResult(response.result);
                setResultErrors(response.errors);
                setTestModalMode('test-result');

                themes.map((theme, index) => {
                    if (theme.id === isTestModalContent.id) {
                        themes[index].test.result = response.result;

                        // под сомнением

                        themes[index].videos.map((video, index) => {
                            if (video.access === 'denied_test' && response.result === 'passed') {
                                themes[index].videos[index].access = 'allow';
                            }
                        });
                    }
                });

                setThemes(themes);
            }

            setLoader(false);
        });
    }

    const TestModal = ({visible = false, content}) => {
        if (!visible) return null;

        const questions = content.test.questions;

        if (isLoader) {
            return (
                <div className="modal position-fixed">
                    <div className="modal-dialog text-center">
                        <div className="modal-header d-block">
                            <div className="modal-header-title">Загрузка результата</div>
                        </div>
                        <div className="modal-confirm pt-4 pb-4">
                            <div className="modal-confirm-title pb-4">Ожидайте</div>
                            <div className="loader">
                                <div className="circle"></div>
                                <div className="circle"></div>
                                <div className="circle"></div>
                                <div className="circle"></div>
                                <div className="circle"></div>
                            </div>
                        </div>
                    </div>
                </div>
            )
        }

        if (isTestModalMode === 'test-start') {
            return (
                <div className="modal position-fixed">
                    <div className="modal-dialog modal-lg text-center">
                        <div className="modal-header" style={{ display: 'block' }}>
                            <div className="text-left">Сдача зачета по теме: { content.title }</div>
                            <div className="modal-header-title w-100 d-flex justify-content-between">
                                { (isQuestionId) < questions.length ? (
                                    <div className="">Вопрос { isQuestionId + 1 } из { questions.length }</div>
                                ) : ('') }
                            </div>
                        </div>
                        { isQuestion ? (
                            <div className="modal-confirm overflow-auto" style={{ maxHeight: 'calc(100vh - 130px)' }}>
                                <img src={ isQuestion.image } style={{ display: 'block', maxWidth: '800px', maxHeight: '800px', width: '100%', height: '100%', margin: '0 auto' }} alt="img" />
                                <div className="text-left mt-4 mr-4 ml-4">
                                    <div className="text-bold pb-2">{ isQuestion.title }</div>
                                    <div className="answers pb-2">
                                        { isQuestion.answers.map((element, key) => {
                                            return (
                                                <div className="custom-control custom-radio pb-2" key={ key }>
                                                    <input className="custom-control-input" type="radio" id={ 'answer-' + element.id } name={ 'answer[' + isQuestion.id + ']' } onChange={ () => answerSelect(isQuestion.id, element.id) } checked={ isSelectValue === element.id } disabled={ isTimeout } />
                                                    <label htmlFor={ 'answer-' + element.id } className="custom-control-label font-weight-normal">{ element.answer }</label>
                                                </div>
                                            )
                                        }) }
                                    </div>
                                    <div className="alert alert-secondary text-center">Выберите один ответ кликнув по нему. Если вы уверены в ответе нажмите "Принять ответ"</div>
                                </div>
                                { !isTimeout ? (
                                    <div className="pagination-block d-flex justify-content-between align-items-baseline mb-4 mr-4 ml-4">
                                        <button type="button" disabled={ !isSelect ? true : false } className={ 'btn btn-xs btn-primary' + (!isSelect ? ' disabled' : '') } onClick={ nextQuestion }>Принять ответ</button>
                                    </div>
                                ) : '' }
                            </div>
                        ) : ('') }
                    </div>
                </div>
            )
        }

        if (isTestModalMode === 'test-result') {
            return (
                <div className="modal position-fixed">
                    <div className="modal-dialog text-center">
                        <div className="modal-header d-block">
                            { isResult === 'passed' ? (
                                <React.Fragment>
                                    <div className="modal-header-title text-green mb-2">Зачет успешно сдан</div>
                                    <div className="d-flex justify-content-center">
                                        <div className="bg-success border p-1 rounded" style={{ cursor: 'pointer' }} onClick={ () => NextTestVideoOpen(isTestModalContent.videos[0]) }>Открыть следующий урок</div>
                                    </div>
                                    <div className="text-primary text-bold mt-3" style={{ cursor: 'pointer' }} onClick={ () => TestModalClose() }>Закрыть</div>
                                </React.Fragment>
                            ) : (
                                <React.Fragment>
                                    <div className="modal-header-title text-red mb-2">Зачет не сдан</div>
                                    { (isResultErrors && isResultErrors === 'time') ? (
                                        <div className="text-bold text-red mb-2" style={{ cursor: 'pointer' }}>Время вышло</div>
                                    ) : (
                                        <div className="text-bold mb-2" style={{ cursor: 'pointer' }} onClick={ () => QuestionList() }>Посмотреть ошибки ({ isResultErrors })</div>
                                    ) }
                                    <div className="d-flex justify-content-center">
                                        <div className="bg-success border p-1 rounded mr-2" style={{ cursor: 'pointer' }} onClick={ () => ThisTestVideoOpen(isTestModalContent.videos[0]) }>Смотреть урок</div>
                                        <div className="bg-danger border p-1 rounded" style={{ cursor: 'pointer' }} onClick={ () => resetTest() }>Пересдать</div>
                                    </div>
                                    <div className="text-primary text-bold mt-3" style={{ cursor: 'pointer' }} onClick={ () => TestModalClose() }>Закрыть</div>
                                </React.Fragment>
                            ) }
                        </div>
                    </div>
                </div>
            )
        }

        if (isTestModalMode === 'test-answers') {
            return (
                <div className="modal position-fixed">
                    <div className="modal-dialog modal-lg text-center">
                        <div className="modal-header">
                            <div className="modal-header-title">Просмотр ошибок</div>
                        </div>
                        { isQuestion ? (
                            <div className="modal-confirm overflow-auto" style={{ maxHeight: 'calc(100vh - 120px)' }}>
                                <div className="d-flex mr-4 ml-4">
                                    <div style={{ margin: '7px', display: 'flex' }}>
                                        <span className="bg-success rounded" style={{ width: '24px', height: '24px', marginTop: '7px' }}></span>
                                        <span className="text-nowrap" style={{ marginLeft: '6px', marginTop: '7px' }}>- правильный ответ</span>
                                    </div>
                                    <div style={{ margin: '7px', display: 'flex' }}>
                                        <span className="bg-primary rounded" style={{ width: '24px', height: '24px', marginTop: '7px' }}></span>
                                        <span className="text-nowrap" style={{ marginLeft: '6px', marginTop: '7px' }}>- ваш ответ</span>
                                    </div>
                                </div>
                                <img src={ isQuestion.image } style={{ display: 'block', maxWidth: '800px', maxHeight: '800px', width: '100%', height: '100%', margin: '0 auto' }} alt="img" />
                                <div className="text-left mt-4 mr-4 ml-4">
                                    <div className="text-bold pb-2">{ isQuestion.title }</div>
                                    <div className="answers pb-2">
                                        { isQuestion.answers.map((element, key) => {
                                            return (
                                                <div className={ (isQuestion.right_answer === element.id ? 'bg-green' : (element.id === isQuestion.select_answer ? 'bg-primary' : '')) + ' border p-1 mb-1 rounded' } key={ key }>{ element.answer }</div>
                                            )
                                        }) }
                                    </div>
                                </div>
                                <div className="pagination-block d-flex justify-content-between mb-4 mr-4 ml-4">
                                    <div className="d-flex">
                                        { isQuestionId > 0 ? (<div className="bg-secondary border p-1 mr-2 rounded" style={{ cursor: 'pointer' }} onClick={ () => QuestionListAnswerClick(isQuestionId-1) }>Предыдущий</div>) : ('') }
                                        { ((isQuestionId + 1) < isQuestions.length) ? (<div className="bg-primary border p-1 rounded" style={{ cursor: 'pointer' }} onClick={ () => QuestionListAnswerClick(isQuestionId+1) }>Следующий</div>) : ('') }
                                    </div>
                                    <div className="bg-danger border p-1 rounded" style={{ cursor: 'pointer' }} onClick={ () => setTestModal(false) }>Закрыть</div>
                                </div>
                            </div>
                        ) : ('') }
                    </div>
                </div>
            )
        }
    }

    let lastTest = false;

    const themesBlock = themes.map((theme, index) => {
        if (!themes) return null;
        if (theme.videos && theme.videos.length === 0) return null;

        if (theme.test && (!theme.test.result || theme.test.result === 'notpassed')) lastTest = theme;

        return (
            <div style={{ marginBottom: '1rem' }} key={ index } >
                <div className="card card-primary collapsed-card"style={{ marginBottom: 0 }}>
                    <div className="card-header" data-card-widget="collapse">
                        <h3 className="card-title" style={{ fontSize: '14px', lineHeight: '1.5' }}>{ theme.title }</h3>
                    </div>
                    <div className="card-body">
                        { theme.videos.map((video, index) => {

                            if (activate === true && video.activate === true && video.access === 'allow') {
                                return (
                                    <div className={ (index + 1) !== theme.videos.length ? 'd-flex align-items-center mb-3' : 'd-flex align-items-center' } style={{ cursor: 'pointer'}} key={ index } onClick={ () => videoOpen(video) }>
                                        <div className="theory-video-preview mr-2 rounded" style={{ backgroundImage: 'url(' + video.thumb + ')' }}></div>
                                        <div className="video-title mr-1">{ video.title }</div>
                                        <div className={ video.viewfull === true ? 'text-green' : '' } style={{ width: '16px' }}>
                                            { video.viewfull === true ? (
                                                <i className="fas fa-check"></i>
                                            ) : ('') }
                                        </div>
                                    </div>
                                )
                            } else {

                                if (video.access === 'denied_pay') {
                                    return (
                                        <div className={ (index + 1) !== theme.videos.length ? 'd-flex align-items-center mb-3' : 'd-flex align-items-center' } style={{ cursor: 'pointer'}} key={ index } onClick={ () => payOpen() }>
                                            <div className="d-flex justify-content-center align-items-center theory-video-preview lock mr-2 rounded" style={{ backgroundImage: 'url(' + video.thumb + ')' }}>
                                                <i className="fas fa-lock text-white"></i>
                                            </div>
                                            <div className="video-title mr-1">{ video.title }</div>
                                            <div className={ video.viewfull === true ? 'text-green' : '' } style={{ width: '16px' }}>
                                                { video.viewfull === true ? (
                                                    <i className="fas fa-check"></i>
                                                ) : ('') }
                                            </div>
                                        </div>
                                    )
                                }

                                if (video.access === 'denied_test') {
                                    return (
                                        <div className={ (index + 1) !== theme.videos.length ? 'd-flex align-items-center mb-3' : 'd-flex align-items-center' } style={{ cursor: 'pointer'}} key={ index } onClick={ () => testStart(lastTest) }>
                                            <div className="d-flex justify-content-center align-items-center theory-video-preview lock mr-2 rounded" style={{ backgroundImage: 'url(' + video.thumb + ')' }}>
                                                <i className="fas fa-lock text-white"></i>
                                            </div>
                                            <div className="video-title mr-1">{ video.title }</div>
                                            <div className={ video.viewfull === true ? 'text-green' : '' } style={{ width: '16px' }}>
                                                { video.viewfull === true ? (
                                                    <i className="fas fa-check"></i>
                                                ) : ('') }
                                            </div>
                                        </div>
                                    )
                                }
                            }
                        })}
                    </div>
                </div>
                { theme.test ? (
                    <div className="ml-2 mt-2 d-flex align-items-baseline">
                        { !theme.test.result || theme.test.result !== 'passed' ? (
                            <div className="text-bold text-primary" style={{ cursor: 'pointer'}} onClick={ () => testStart(theme) }>Сдать зачет</div>
                        ) : (
                            <div className="text-bold text-primary">Зачет</div>
                        ) }
                        <div className={ 'ml-2' + (theme.test.result ? (theme.test.result === 'passed' ? ' text-green' : ' text-red') : '') }>({ theme.test.result ? (theme.test.result === 'passed' ? 'сдан' : 'не сдан') : 'не пройден' })</div>
                    </div>
                ) : ('') }
            </div>
        )
    });

    const playDuration = (event, duration) => {
        showTimer = setInterval(() => {
            const data = {
                path: 'client/theory/pdd/videos/time/save',
                client_id: props.auth.client_id,
                video_id: isVideoModalContent.id,
                current_time: Math.round(event.detail.plyr.currentTime),
                date: dateVideo
            };

            if (event.detail.plyr.currentTime === duration) {
                data.full = true;

                let themesTemp = themes;

                themesTemp.forEach((theme, thIndex) => {
                    theme.videos.forEach((video, qIndex) => {
                        if (video.id === isVideoModalContent.id) {
                            video.viewfull = true;
                        }
                    })
                });

                setThemes(themesTemp);
            }

            if (props.auth.school_id) {
                data.school_id = props.auth.school_id;
            }

            axios.post(API_SCRIPT, data, config).then(response => {
                response = response.data;

                if (response.status === 'done') {
                    dateVideo = response.date
                }
            });

            if (event.detail.plyr.currentTime === duration) {
                clearInterval(showTimer);
                dateVideo = false;
            }
        }, 30000);
    }

    const VideoModal = ({visible = false, content}) => {
        if (!visible) return null;

        let video = false;

        const data = {
            path: 'client/theory/pdd/videos/get',
            video: content.id,
        };

        axios.post(API_SCRIPT, data, config).then(response => {
            response = response.data;

            if (response.status === 'done') {
                video = response.video;

                const options = {
                    i18n: {
                        quality: 'Качество',
                        speed: 'Скорость',
                        normal: 'Обычная'
                    },
                    controls: [
                        'play-large', 'play', 'progress', 'current-time', 'duration', 'mute', 'fullscreen',
                    ]
                }

                const source = {
                    type: 'video',
                    title: content.title,
                    sources: [
                        {
                            src: video.v1080p,
                            type: 'video/mp4',
                            size: 1080
                        }
                    ],
                    poster: content.image,
                    previewThumbnails: {
                        src: content.thumb
                    }
                };

                setTimeout(() => {
                    const player = new Plyr('#player', options);
                    player.source = source;
                    player.play()
                    player.on('playing', event => playDuration(event, player.duration));
                    player.on('ended', event => playDuration(event, player.duration));
                }, 500);
            }
        });

        return (
            <div className="theory-video-modal position-fixed">
                <div className="theory-video-modal-dialog text-center">
                    <div className="theory-video-modal-header">
                        <div className="theory-video-modal-header-title">{ content.theme }</div>
                    </div>
                    <div className="theory-video-modal-body">
                        <div className="mb-2">{ content.title }</div>
                        <video id="player"></video>
                        <div className="btn btn-primary w-100 mt-3" onClick={ () => videoClose() }>Закрыть</div>
                    </div>
                </div>
            </div>
        )
    }

    const PayModal = ({visible = false}) => {
        if (!visible) return null;

        return (
            <div className="theory-video-modal position-fixed">
                <div className="theory-video-modal-dialog text-center">
                    <div className="theory-video-modal-body">
                        <div className="text-bold">Необходимо оплатить доступ для просмотра видеоуроков</div>

                        { price !== false ? (
                            isLoaded === false ? (
                                <div className="payinfo">
                                    <div className="m-3 text-bold text-gray">Стоимость { price } руб.</div>
                                    <div className="btn btn-success" onClick={ () => PayCreate() }>Оплатить</div>
                                </div>
                            ) : (
                                <div className="p-3">
                                    <div className="loader">
                                        <div className="circle"></div>
                                        <div className="circle"></div>
                                        <div className="circle"></div>
                                        <div className="circle"></div>
                                        <div className="circle"></div>
                                    </div>
                                    <div className="text-sm text-bold">Вы будете перенаправлены в платежную систему!</div>
                                </div>
                            )
                        ) : (
                            <div className="m-3 text-bold text-red">Оплата в данный момент невозможна</div>
                        ) }
                        <div className="btn btn-primary w-100 mt-3" onClick={ () => payClose() }>Закрыть</div>
                    </div>
                </div>
            </div>
        )
    }

    const PayCreate = () => {
        let data = {
            path: 'client/theory/pdd/pay',
            client_id: props.auth.client_id,
            redirect: redirectURI
        };

        if (props.auth.school_id) {
            data.school_id = props.auth.school_id;
        }

        setIsLoaded(true);

        axios.post(API_SCRIPT, data, config).then(response => {
            response = response.data;

            if (response.status === 'done') {
                window.location.href = response.url
            }
        });
    }

    return (
        <React.Fragment>
            <div className="card ml-3 mr-3 mt-3 mb-3">
                <div className="card-body">
                    <div className="text-center">
                        <div className="text-bold mb-4">Видео уроки</div>
                        <div className="text-gray text-bold mb-4">Просмотрено { videosFullViewsCount } / { videosCount }</div>
                    </div>
                    <div>
                        { isLoaded === false ? (
                            <div className="videos mt-3">
                                <div className="text-gray text-bold">Выберите тему</div>
                                <div className="themes mt-2">{ themesBlock }</div>
                            </div>
                        ) : (
                            <div className="loader">
                                <div className="circle"></div>
                                <div className="circle"></div>
                                <div className="circle"></div>
                                <div className="circle"></div>
                                <div className="circle"></div>
                            </div>
                        ) }
                    </div>
                </div>
            </div>
            <div className="text-center ml-3 mr-3">
                { (props.auth.school_id && props.auth.type !== 'pdd') ? '' : (<NavLink to="/" className="btn btn-primary text-bold w-100">Назад</NavLink>) }
            </div>
            <VideoModal visible={ isVideoModal } content={ isVideoModalContent }/>
            <TestModal visible={ isTestModal } content={ isTestModalContent }/>
            <PayModal visible={ isPayModal }/>
            <Toaster/>
        </React.Fragment>
    )
}

export default TheoryVideos;
