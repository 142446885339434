import React, { useEffect, useState } from "react";
import toast, { Toaster } from 'react-hot-toast';
import CloseSidebar from "../../Main/CloseSidebar";
import axios from "axios";
import { YMaps, Map, Clusterer, Placemark } from "@pbe/react-yandex-maps";

const Internal = (props) => {
    CloseSidebar();

    const API_SCRIPT = process.env.REACT_APP_API_SCRIPT;
    const YMAPS_API_KEY = 'a670d490-12b6-44e8-bb94-137f7813ebd3';
    const [isLoaded, setIsLoaded] = useState(false);
    const [data, setData] = useState([]);

    const [isModal, setModal] = useState(false);
    const [isModalContent, setModalContent] = useState([]);

    const [isCancelModal, setCancelModal] = useState(false);
    const [isCancelModalContent, setCancelModalContent] = useState([]);

    const [selectExamen, setSelectExamen] = useState(false);
    const [theoryTime, setTheoryTime] = useState(false);
    const [practiceTime, setPracticeTime] = useState(false);

    const [isEmpty, setEmpty] = useState(false);

    // окно пополнения
    const [isPayModal, setPayModal] = useState(false);
    const [isPayModalAmount, setPayModalAmount] = useState(0);

    const config = {
        headers : {
            'Content-Type' : 'application/x-www-form-urlencoded'
        }
    };

    useEffect(() => {
        document.title = 'Экзамены | Права Мобил';

        const data = {
            path: 'client/examens/internal/info',
            school_id: props.auth.school_id,
            client_id: props.auth.client_id
        };

        setIsLoaded(true);

        axios.post(API_SCRIPT, data, config).then(response => {
            response = response.data;

            if (response.status === 'done') {
                if (response.type.theory === 'group' && response.future && (!response.future.theory && !response.future.practice)) {
                    setEmpty(true);
                } else {
                    setData(response);
                }

                setIsLoaded(false);
            } else {
                toast.error(response.message, {
                    duration: 5000,
                    position: 'bottom-center'
                });
            }
        });
    }, []);

    const ExamenList = type => {
        const data = {
            path: 'client/examens/internal/list',
            school_id: props.auth.school_id,
            client_id: props.auth.client_id,
            examen_type: type
        };

        setIsLoaded(true);

        axios.post(API_SCRIPT, data, config).then(response => {
            response = response.data;

            if (response.status === 'done') {
                setModalContent(response);
                setPayModalAmount(response.amount);
                setModal(true);
                setIsLoaded(false);
            } else {
                toast.error(response.message, {
                    duration: 5000,
                    position: 'bottom-center'
                });
            }
        });
    }

    const ExamenWrite = () => {
        if (selectExamen) {
            const data = {
                path: 'client/examens/internal/write',
                school_id: props.auth.school_id,
                client_id: props.auth.client_id,
                examen_id: selectExamen
            };

            if (theoryTime) data.theory_time = theoryTime;
            if (practiceTime) data.practice_time = practiceTime;

            setIsLoaded(true);

            axios.post(API_SCRIPT, data, config).then(response => {
                response = response.data;

                if (response.status === 'done') {
                    window.location.reload()
                } else {
                    toast.error(response.message, {
                        duration: 5000,
                        position: 'bottom-center'
                    });
                }
            });
        } else {
            toast.error('Экзамен не выбран', {
                duration: 5000,
                position: 'bottom-center'
            });
        }
    }

    const ExamenCancel = id => {
        setCancelModalContent(id);
        setCancelModal(true);
    }

    const ExamenCancelSend = id => {
        const data = {
            path: 'client/examens/internal/cancel',
            school_id: props.auth.school_id,
            client_id: props.auth.client_id,
            examen_id: id
        };

        setIsLoaded(true);

        axios.post(API_SCRIPT, data, config).then(response => {
            response = response.data;

            if (response.status === 'done') {
                window.location.reload();
                setIsLoaded(false);
            } else {
                toast.error(response.message, {
                    duration: 5000,
                    position: 'bottom-center'
                });
            }
        });
    }

    const setSelectExamenTime = (id, type, value) => {
        if (type === 'theory') setTheoryTime(value);
        if (type === 'practice') setPracticeTime(value);

        setSelectExamen(id);
    }

    // открытие окна пополнения
    const payOpen = () => {
        setPayModal(true);
    }

    // закрытие окна пополнения
    const payClose = () => {
        setPayModal(false);
        setIsLoaded(false);
    }

    // обработка формы пополнения
    const onSubmitForm = event => {
        event.preventDefault()

        setIsLoaded(true);

        const data = {
            path: 'pay/deposit',
            school_id: props.auth.school_id,
            client_id: props.auth.client_id,
            amount: event.target[0].value
        };

        axios.post(API_SCRIPT, data, config).then(response => {
            response = response.data;

            if (response.status === 'done') {
                window.location.replace(response.url)
            }
        })
    }

    // окно пополнения
    const PayModal = ({visible = false, amount}) => {
        if (!visible) return null;

        return (
            <div className="modal position-fixed">
                <div className="modal-dialog text-center">
                    <div className="modal-body">
                        <div className="text-bold">Пополнение баланса</div>
                        <div className="text-gray small">Введите сумму, на которую хотите пополнить баланс, и нажмите "Оплатить"</div>

                        { isLoaded === false ? (
                            <form className="payinfo" onSubmit={ onSubmitForm }>
                                <input type="number" className="form-control mt-3 mb-3" name="amount" min="1" step="1" defaultValue={ amount } required />
                                <button type="submit" className="btn btn-success">Оплатить</button>
                            </form>
                        ) : (
                            <div className="p-3">
                                <div className="loader">
                                    <div className="circle"></div>
                                    <div className="circle"></div>
                                    <div className="circle"></div>
                                    <div className="circle"></div>
                                    <div className="circle"></div>
                                </div>
                                <div className="text-sm text-bold">Вы будете перенаправлены в платежную систему!</div>
                            </div>
                        ) }

                        <div className="btn btn-primary w-100 mt-3" onClick={ () => payClose() }>Закрыть</div>
                    </div>
                </div>
            </div>
        )
    }

    const Modal = ({visible = false, content}) => {
        if (!visible) return null;

        let errors,
            examens;

        if (content.errors) {
            if (content.messages.theory || content.messages.practice) {
                errors = Object.keys(content.messages).map((type, key) => {
                    return <div className="d-block mb-2" key={ key }>
                        <div className="text-bold pl-3">{ type === 'theory' ? 'Теория' : 'Практика' }</div>
                        { content.messages[type].map((item, key) => {
                            return <div className="text-red pl-3" key={ key }>• { item }</div>;
                        }) }
                    </div>
                })
            } else {
                errors = content.messages.map((item, key) => {
                    return <div className="text-red pl-3" key={ key }>• { item }</div>;
                })
            }
        }

        if (content.examens) {
            examens = content.examens.map((item, key) => {

                const theoryTimes = item.theory_time.map((time, key) => {
                    return <option key={ key }>{ time }</option>;
                })

                const practiceTimes = item.practice_time.map((time, key) => {
                    return <option key={ key }>{ time }</option>;
                })

                return (
                    <div className="border border-primary p-2 mb-2" key={ key }>
                        { item.theory_date && item.theory_time ? (
                            item.theory_time.length > 1 ? (
                                <div className={ 'btn border-primary mt-1 mb-1 w-100' + (selectExamen === item.id ? ' bg-primary' : '') }>
                                    <div className="d-flex justify-content-between align-items-baseline">
                                        <div className="text-bold">Теория</div>
                                        <div className="d-flex justify-content-between align-items-baseline">
                                            <div className="text-bold mr-2">{ item.theory_date }</div>
                                            <select className="custom-select" value={ theoryTime } onChange={ (event) => setSelectExamenTime(item.id, 'theory', event.target.value) }>
                                                { theoryTimes }
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            ) : (
                                <div className={ 'btn border-primary mt-1 mb-1 w-100' + (selectExamen === item.id ? ' bg-primary' : '') } onClick={ () => setSelectExamen(item.id) }>
                                    <div className="d-flex justify-content-between align-items-baseline">
                                        <div className="text-bold">Теория</div>
                                        <div className="text-bold">{ item.theory_date } ({ item.theory_time })</div>
                                    </div>
                                </div>
                            )
                        ) : ('') }

                        { item.practice_date && item.practice_time ? (
                            item.practice_time.length > 1 ? (
                                <div className={ 'btn border-primary mt-1 mb-1 w-100' + (selectExamen === item.id ? ' bg-primary' : '') }>
                                    <div className="d-flex justify-content-between align-items-baseline">
                                        <div className="text-bold">Практика { item.practice_kpp !== '' ? '(' + item.practice_kpp + ')' : '' }</div>
                                        <div className="d-flex justify-content-between align-items-baseline">
                                            <div className="text-bold mr-2">{ item.practice_date }</div>
                                            <select className="custom-select" value={ practiceTime } onChange={ (event) => setSelectExamenTime(item.id, 'practice', event.target.value) }>
                                                { practiceTimes }
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            ) : (
                                <div className={ 'btn border-primary mt-1 mb-1 w-100' + (selectExamen === item.id ? ' bg-primary' : '') } onClick={ () => setSelectExamen(item.id) }>
                                    <div className="d-flex justify-content-between align-items-baseline">
                                        <div className="text-bold">Практика { item.practice_kpp !== '' ? '(' + item.practice_kpp + ')' : '' }</div>
                                        <div className="text-bold">{ item.practice_date } ({ item.practice_time })</div>
                                    </div>
                                </div>
                            )
                        ) : ('') }
                    </div>
                )
            });
        }

        return (
            <div className="modal position-fixed">
                <div className="modal-dialog">
                    <div className="modal-header text-center d-block">
                        <div className="modal-header-title">{ content.errors ? 'Экзамены недоступны' : 'Список доступных экзаменов' }</div>
                    </div>
                    <div className="modal-confirm">
                        <div className="errors">{ errors }</div>
                        <div className="examens m-4">{ examens }</div>
                        { content.needpay === true ? (
                            content.schoolPayExist === true ? (
                                <div className="d-flex justify-content-center">
                                    <button type="button" className="btn btn-success" onClick={ () => payOpen() }>Пополнить баланс</button>
                                </div>
                            ) : (
                                <div className="error-alert pt-4">
                                    <div className="bg-white text-center ml-4 mr-4 pt-4 pb-4">
                                        <img src="https://client.pravamobil.ru/dist/img/alert-error.png" alt="" />
                                        <div className="text-bold mt-4">Оплата не доступна!<br />Обратитесь в свою автошколу!</div>
                                    </div>
                                </div>
                            )
                        ) : '' }
                        <div className="modal-confirm-btn-block d-flex justify-content-center">
                            <div className="modal-confirm-btn">
                                <div className="confirm-btn-no" onClick={ () => modalClose() }>Закрыть</div>
                            </div>
                            <div className={ 'modal-confirm-btn' + (content.errors ? ' d-none' : '') }>
                                <div className={ 'confirm-btn-yes' + (!selectExamen ? ' bg-red border-danger' : '') } onClick={ () => ExamenWrite() }>Записаться</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    const modalClose = () => {
        setModal(false);
        setModalContent([]);
        setSelectExamen(false);

        setTheoryTime(false);
        setPracticeTime(false);
    }

    const ExamenResultSwitch = (result) => {
        switch (result) {
            case 'passed': return 'Сдана';
            case 'start':
            case 'notpassed': return 'Не сдана';
            case 'notallowed': return 'Не допущен';
            case 'absence': return 'Прогул';
        }
    }

    const CancelModal = ({visible = false, content}) => {
        if (!visible) return null;

        return (
            <div className="modal position-fixed">
                <div className="modal-dialog">
                    <div className="modal-header text-center d-block">
                        <div className="modal-header-title">Отменить запись на экзамен?</div>
                    </div>
                    <div className="modal-confirm">
                        <div className="modal-confirm-btn-block d-flex justify-content-center">
                            <div className="modal-confirm-btn">
                                <div className="confirm-btn-no" onClick={ () => modalCancelClose() }>Закрыть</div>
                            </div>
                            <div className="modal-confirm-btn">
                                <div className="confirm-btn-yes bg-red border-danger" onClick={ () => ExamenCancelSend(content) }>Отменить</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    const modalCancelClose = () => {
        setCancelModal(false);
        setCancelModalContent([]);
    }

    if (isEmpty) {
        return (
            <React.Fragment>
                <div className="error-alert pt-4">
                    <div className="bg-white text-center ml-4 mr-4 pt-4 pb-4">
                        <img src="https://client.pravamobil.ru/dist/img/alert-error.png" alt="" />
                        <div className="text-bold mt-4">Экзамены не назначены!<br />Обратитесь в свою автошколу!</div>
                    </div>
                </div>
            </React.Fragment>
        )
    }

    return (
        <React.Fragment>
            { (isLoaded === false && data) ? (
                <div className="examen-inner text-md-left">
                    { data.type && (data.type.theory !== 'examen-common' && data.type.practice !== 'examen-common') ? (
                        <div className="examens">
                            <div className="card m-3">
                                { (data.future && data.future.theory) ? (
                                    <div className="card-body">
                                        <div className="text-bold mb-2">{ (data.before && data.before.theory && data.before.theory !== 'passed') ? 'Теория повторно' : 'Теория первично' }</div>
                                        <div className="row">
                                            <div className="col-12 col-md-6">
                                                <div className="d-block mb-2 mb-md-4">
                                                    <div className="mb-2">Вы записаны на { data.future.theory.date } ({ data.future.theory.time })</div>
                                                    <div className="text-bold">{ data.future.theory.location.address }</div>
                                                    <div className="text-black-50">{ data.schedule && data.schedule.theory ? data.schedule.theory : '' }</div>
                                                </div>
                                                { data.future.theory.cancel_btn ? (
                                                    <div className="btn btn-danger d-none d-md-inline" onClick={ () => ExamenCancel(data.future.theory.examen) }>Отменить запись</div>
                                                ) : ('') }
                                            </div>
                                            <div className="col-12 col-md-6">
                                                <YMaps query={{ apikey: YMAPS_API_KEY, lang: 'ru_RU' }}>
                                                    <Map state={{ center: data.future.theory.location.coords, zoom: 16 }} options={{ suppressMapOpenBlock: true }} style={{ height: '200px', width: '100%' }}>
                                                        <Clusterer options={{ preset: 'islands#darkBlueDotIconWithCaption' }}>
                                                            <Placemark geometry={ data.future.theory.location.coords }/>
                                                        </Clusterer>
                                                    </Map>
                                                </YMaps>
                                                { data.future.theory.cancel_btn ? (
                                                    <div className="text-center mt-3">
                                                        <div className="btn btn-danger d-md-none" onClick={ () => ExamenCancel(data.future.theory.examen) }>Отменить запись</div>
                                                    </div>
                                                ) : ('') }
                                            </div>
                                        </div>
                                    </div>
                                ) : (
                                    <div className="card-body">
                                        <div className="d-flex justify-content-between flex-wrap">
                                            <div className="text-bold mb-2">{ (data.before && data.before.theory && data.before.theory !== 'passed') ? 'Теория повторно' : 'Теория первично' }</div>
                                            { (data.type && data.type.theory && (data.type.theory === 'group' || data.type.theory === 'examen-regularly')) ? (
                                                data.type.theory === 'group' ? (
                                                    <div className="text-green">Назначается школой</div>
                                                ) : (
                                                    <div className="text-green">Без записи</div>
                                                )
                                            ) : (
                                                <div className="text-red">Требует записи</div>
                                            ) }
                                        </div>
                                        { (data.type && data.type.theory && (data.type.theory === 'group' || data.type.theory === 'examen-regularly')) ? (
                                            (data.type.theory === 'group' && data.future.theory) ? (
                                                <div className="row">
                                                    <div className="col-12 col-md-6">
                                                        <div className="mb-2">Вы записаны на { data.future.theory.date } ({ data.future.theory.time })</div>
                                                        <div className="text-bold">{ data.future.theory.location.address }</div>
                                                        <div className="text-black-50 mt-2">{ data.schedule && data.schedule.theory ? data.schedule.theory : '' }</div>
                                                    </div>
                                                    <div className="col-12 col-md-6">
                                                        <YMaps query={{ apikey: YMAPS_API_KEY, lang: 'ru_RU' }}>
                                                            <Map state={{ center: data.future.theory.location.coords, zoom: 16 }} options={{ suppressMapOpenBlock: true }} style={{ height: '200px', width: '100%' }}>
                                                                <Clusterer options={{ preset: 'islands#darkBlueDotIconWithCaption' }}>
                                                                    <Placemark geometry={ data.future.theory.location.coords }/>
                                                                </Clusterer>
                                                            </Map>
                                                        </YMaps>
                                                    </div>
                                                </div>
                                            ) : (
                                                <div className="d-block">
                                                    <div className="text-black-50">{ data.schedule.theory }</div>
                                                </div>
                                            )
                                        ) : ('') }
                                        { (data.before && data.before.theory) ? (
                                            <div className="">
                                                <div className="text-bold">Результат последней сдачи теории: <span className={ data.before.theory !== 'passed' ? 'text-red' : 'text-green' }>{ ExamenResultSwitch(data.before.theory) + (data.before.theory === 'passed' ? (' (действует до ' + data.before.theory_date + ')') : '') }</span></div>
                                                { data.before.theory !== 'passed' ? (
                                                    <div className="d-flex pt-3 pb-3 mt-2" style={{ border: '2px solid #FFA723', color: '#FFA723', borderRadius: '10px' }}>
                                                        <div className="ml-3">
                                                            <i className="fa fa-info-circle"></i>
                                                        </div>
                                                        <div className="ml-3 text-bold">Необходимо пересдать</div>
                                                    </div>
                                                ) : ('') }
                                            </div>
                                        ) : ('') }
                                        { (data.type && data.type.theory && (data.type.theory !== 'group' && data.type.theory !== 'examen-regularly') && (data.before && (!data.before.theory || (data.before.theory && data.before.theory !== 'passed')))) ? (
                                            <div className="btn btn-primary mt-2" onClick={ () => ExamenList('theory') }>Записаться</div>
                                        ) : ('') }
                                        { (data.type && data.type.theory && data.type.theory === 'group' && !data.future.theory) ? (
                                            <div className="text-orange mt-3">
                                                <i className="fas fa-info-circle"></i>
                                                <span className="ml-2">Экзамен не назначен. О дате экзамена вы получите уведомление</span>
                                            </div>
                                        ) : ('') }
                                    </div>
                                ) }
                            </div>
                            <div className="card m-3">
                                { (data.future && data.future.practice) ? (
                                    <div className="card-body">
                                        <div className="text-bold mb-2">{ (data.before && data.before.practice && data.before.practice !== 'passed') ? 'Практика повторно' : 'Практика первично' }</div>
                                        <div className="row">
                                            <div className="col-12 col-md-6">
                                                <div className="d-block mb-4">
                                                    <div className="mb-2">Вы записаны на { data.future.practice.date } ({ data.future.practice.time })</div>
                                                    <div className="text-bold">{ data.future.practice.location.address }</div>
                                                    <div className="text-black-50">{ data.schedule && data.schedule.practice ? data.schedule.practice : '' }</div>
                                                </div>
                                                { data.future.practice.cancel_btn ? (
                                                    <div className="btn btn-danger d-none d-md-inline" onClick={ () => ExamenCancel(data.future.practice.examen) }>Отменить запись</div>
                                                ) : ('') }
                                            </div>
                                            <div className="col-12 col-md-6">
                                                <YMaps query={{ apikey: YMAPS_API_KEY, lang: 'ru_RU' }}>
                                                    <Map state={{ center: data.future.practice.location.coords, zoom: 16 }} options={{ suppressMapOpenBlock: true }} style={{ height: '200px', width: '100%' }}>
                                                        <Clusterer options={{ preset: 'islands#darkBlueDotIconWithCaption' }}>
                                                            <Placemark geometry={ data.future.practice.location.coords }/>
                                                        </Clusterer>
                                                    </Map>
                                                </YMaps>
                                                { data.future.practice.cancel_btn ? (
                                                    <div className="text-center mt-3">
                                                        <div className="btn btn-danger d-md-none" onClick={ () => ExamenCancel(data.future.practice.examen) }>Отменить запись</div>
                                                    </div>
                                                ) : ('') }
                                            </div>
                                        </div>
                                    </div>
                                ) : (
                                    <div className="card-body">
                                        <div className="d-flex justify-content-between flex-wrap">
                                            <div className="text-bold mb-2">{ (data.before && data.before.practice && data.before.practice !== 'passed') ? 'Практика повторно' : 'Практика первично' }</div>
                                            { (data.type && data.type.practice && (data.type.practice === 'group' || data.type.practice === 'examen-regularly')) ? (
                                                data.type.practice === 'group' ? (
                                                    <div className="text-green">Назначается школой</div>
                                                ) : (
                                                    <div className="text-green">Без записи</div>
                                                )
                                            ) : (
                                                <div className="text-red">Требует записи</div>
                                            ) }
                                        </div>
                                        { (data.type && data.type.practice && (data.type.practice === 'group' || data.type.practice === 'examen-regularly')) ? (
                                            (data.type.practice === 'group' && data.future.practice) ? (
                                                <div className="row">
                                                    <div className="col-12 col-md-6">
                                                        <div className="mb-2">Вы записаны на { data.future.practice.date } ({ data.future.practice.time })</div>
                                                        <div className="text-bold">{ data.future.practice.location.address }</div>
                                                        <div className="text-black-50">{ data.schedule && data.schedule.practice ? data.schedule.practice : '' }</div>
                                                    </div>
                                                    <div className="col-12 col-md-6">
                                                        <YMaps query={{ apikey: YMAPS_API_KEY, lang: 'ru_RU' }}>
                                                            <Map state={{ center: data.future.practice.location.coords, zoom: 16 }} options={{ suppressMapOpenBlock: true }} style={{ height: '200px', width: '100%' }}>
                                                                <Clusterer options={{ preset: 'islands#darkBlueDotIconWithCaption' }}>
                                                                    <Placemark geometry={ data.future.practice.location.coords }/>
                                                                </Clusterer>
                                                            </Map>
                                                        </YMaps>
                                                    </div>
                                                </div>
                                            ) : (
                                                <div className="d-block">
                                                    <div className="text-black-50">{ data.schedule.practice }</div>
                                                </div>
                                            )
                                        ) : ('') }
                                        { (data.before && data.before.practice) ? (
                                            <div className="">
                                                <div className="text-bold">Результат последней сдачи практики: <span className={ data.before.practice !== 'passed' ? 'text-red' : 'text-green' }>{ ExamenResultSwitch(data.before.practice) }</span></div>
                                                { data.before.practice !== 'passed' ? (
                                                    <div className="d-flex pt-3 pb-3 mt-2" style={{ border: '2px solid #FFA723', color: '#FFA723', borderRadius: '10px' }}>
                                                        <div className="ml-3">
                                                            <i className="fa fa-info-circle"></i>
                                                        </div>
                                                        <div className="ml-3 text-bold">Необходимо пересдать</div>
                                                    </div>
                                                ) : ('') }
                                            </div>
                                        ) : ('') }

                                        { (data.type && data.type.practice && (data.type.practice !== 'group' && data.type.practice !== 'examen-regularly') && (data.before && (!data.before.practice || (data.before.practice && data.before.practice !== 'passed')))) ? (
                                            <div className="btn btn-primary mt-2" onClick={ () => ExamenList('practice') }>Записаться</div>
                                        ) : ('') }

                                        { (data.type && data.type.practice && data.type.practice === 'group' && !data.future.practice) ? (
                                            <div className="text-orange mt-3">
                                                <i className="fas fa-info-circle"></i>
                                                <span className="ml-2">Экзамен не назначен. О дате экзамена вы получите уведомление</span>
                                            </div>
                                        ) : ('') }
                                    </div>
                                ) }
                            </div>
                        </div>
                    ) : (
                        <div className="examens">
                            <div className="card m-3">
                                { (data.future && data.future.theory && data.future.practice) ? (
                                    <div className="card-body">
                                        <div className="text-bold mb-4">{ (data.before && data.before.theory && data.before.theory !== 'passed') ? 'Теория повторно' : 'Теория первично' } и { (data.before && data.before.practice && data.before.practice !== 'passed') ? 'практика повторно' : 'практика первично' }</div>
                                        <div className="row">
                                            <div className="col-12 col-md-4">
                                                <div className="mb-4">
                                                    <div className="mb-2">Теория: { data.future.theory.date } ({ data.future.theory.time })</div>
                                                    <div className="text-black-50 mb-2">{ data.schedule && data.schedule.theory ? data.schedule.theory : '' }</div>
                                                    <div className="mb-2">Практика: { data.future.practice.date } ({ data.future.practice.time })</div>
                                                    <div className="text-black-50">{ data.schedule && data.schedule.practice ? data.schedule.practice : '' }</div>
                                                </div>
                                                { data.future.theory.cancel_btn ? (
                                                    <div className="btn btn-danger d-none d-md-inline" onClick={ () => ExamenCancel(data.future.practice.examen) }>Отменить запись</div>
                                                ) : ('') }
                                            </div>
                                            <div className="col-12 col-md-4">
                                                <YMaps query={{ apikey: YMAPS_API_KEY, lang: 'ru_RU' }}>
                                                    <Map state={{ center: data.future.theory.location.coords, zoom: 16 }} options={{ suppressMapOpenBlock: true }} style={{ height: '200px', width: '100%' }}>
                                                        <Clusterer options={{ preset: 'islands#darkBlueDotIconWithCaption' }}>
                                                            <Placemark geometry={ data.future.theory.location.coords }/>
                                                        </Clusterer>
                                                    </Map>
                                                </YMaps>
                                                <div className="text-center"><span className="text-bold">Адрес теории:</span> { data.future.theory.location.address }</div>
                                            </div>
                                            <div className="col-12 col-md-4">
                                                <YMaps query={{ apikey: YMAPS_API_KEY, lang: 'ru_RU' }}>
                                                    <Map state={{ center: data.future.practice.location.coords, zoom: 16 }} options={{ suppressMapOpenBlock: true }} style={{ height: '200px', width: '100%' }}>
                                                        <Clusterer options={{ preset: 'islands#darkBlueDotIconWithCaption' }}>
                                                            <Placemark geometry={ data.future.practice.location.coords }/>
                                                        </Clusterer>
                                                    </Map>
                                                </YMaps>
                                                <div className="text-center"><span className="text-bold">Адрес практики:</span> { data.future.practice.location.address }</div>
                                            </div>
                                        </div>
                                        { data.future.theory.cancel_btn ? (
                                            <div className="text-center mt-3">
                                                <div className="btn btn-danger d-md-none" onClick={ () => ExamenCancel(data.future.practice.examen) }>Отменить запись</div>
                                            </div>
                                        ) : ('') }
                                    </div>
                                ) : (
                                    <div className="card-body">
                                        <div className="d-flex justify-content-between flex-wrap">
                                            <div className="text-bold mb-2">{ (data.before && data.before.theory && data.before.theory !== 'passed') ? 'Теория повторно' : 'Теория первично' } и { (data.before && data.before.practice && data.before.practice !== 'passed') ? 'практика повторно' : 'практика первично' }</div>
                                            <div className="text-red">Требует записи</div>
                                        </div>
                                        { (data.before && (data.before.theory || data.before.practice)) ? (
                                            <div className="">
                                                { data.before.theory ? (
                                                    <div className="text-bold mb-2">Результат последней сдачи теории: <span className={ data.before.theory !== 'passed' ? 'text-red' : 'text-green' }>{ ExamenResultSwitch(data.before.theory) }</span></div>
                                                ) : ('') }
                                                { data.before.practice ? (
                                                    <div className="text-bold mb-2">Результат последней сдачи практики: <span className={ data.before.practice !== 'passed' ? 'text-red' : 'text-green' }>{ ExamenResultSwitch(data.before.practice) }</span></div>
                                                ) : ('') }
                                                { (data.before.theory !== 'passed') ? (
                                                    <div className="d-flex pt-3 pb-3 mt-2" style={{ border: '2px solid #FFA723', color: '#FFA723', borderRadius: '10px' }}>
                                                        <div className="ml-3">
                                                            <i className="fa fa-info-circle"></i>
                                                        </div>
                                                        <div className="ml-3 text-bold">Необходимо пересдать</div>
                                                    </div>
                                                ) : ('') }
                                            </div>
                                        ) : ('') }
                                        { (data.before && (data.before.theory !== 'passed' && data.before.theory !== 'start')) ? (
                                            <div className="btn btn-primary mt-2" onClick={ () => ExamenList('all') }>Записаться</div>
                                        ) : ('') }
                                    </div>
                                ) }
                            </div>
                        </div>
                    ) }
                </div>
            ) : (
                <div className="loader">
                    <div className="circle"></div>
                    <div className="circle"></div>
                    <div className="circle"></div>
                    <div className="circle"></div>
                    <div className="circle"></div>
                </div>
            ) }

            <Modal visible={ isModal } content={ isModalContent } />
            <PayModal visible={ isPayModal } amount={ isPayModalAmount }/>
            <CancelModal visible={ isCancelModal } content={ isCancelModalContent } />
            <Toaster/>
        </React.Fragment>
    )
}

export default Internal;
